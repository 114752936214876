import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import WheelOfFortune from "../WheelOfFortune"
import Image from "../../image"
import { Styles } from "./style"

const perusahaanList = [
  {
    slug: "wof",
    imgBefore: "challange_wof_before.png",
    imgAfter: "challange_wof_after.png",
  },
  {
    slug: "compfest",
    imgBefore: "challange_compfest_before.png",
    imgAfter: "challange_compfest_after.png",
  },
  {
    slug: "cbn",
    imgBefore: "challange_cbn_before.png",
    imgAfter: "challange_cbn_after.png",
  },
  {
    slug: "bibit",
    imgBefore: "challange_bibit_before.png",
    imgAfter: "challange_bibit_after.png",
  },
  {
    slug: "crewdible",
    imgBefore: "challange_crewdible_before.png",
    imgAfter: "challange_crewdible_after.png",
  },
  {
    slug: "antigrvty",
    imgBefore: "challange_antigrav_before.png",
    imgAfter: "challange_antigrav_after.png",
  },
  {
    slug: "megaxus",
    imgBefore: "challange_megaxus_before.png",
    imgAfter: "challange_megaxus_after.png",
  },
  {
    slug: "paper",
    imgBefore: "challange_paper_before.png",
    imgAfter: "challange_paper_after.png",
  },
  {
    slug: "althaconsulting",
    imgBefore: "challange_altha_before.png",
    imgAfter: "challange_altha_after.png",
  },
  {
    slug: "icehouse",
    imgBefore: "challange_icehouse_before.png",
    imgAfter: "challange_icehouse_after.png",
  },
  {
    slug: "ecomindo",
    imgBefore: "challange_ecomindo_before.png",
    imgAfter: "challange_ecomindo_after.png",
  },
  {
    slug: "sirclo",
    imgBefore: "challange_sirclo_before.png",
    imgAfter: "challange_sirclo_after.png",
  },
  {
    slug: "skilvul",
    imgBefore: "challange_skilvul_before.png",
    imgAfter: "challange_skilvul_after.png",
  },
  {
    slug: "pinhome",
    imgBefore: "challange_pinhome_before.png",
    imgAfter: "challange_pinhome_after.png",
  },
  {
    slug: "stockbit",
    imgBefore: "challange_stockbit_before.png",
    imgAfter: "challange_stockbit_after.png",
  },
  {
    slug: "higo",
    imgBefore: "challange_higo_before.png",
    imgAfter: "challange_higo_after.png",
  },
  {
    slug: "shopee",
    imgBefore: "challange_shopee_before.png",
    imgAfter: "challange_shopee_after.png",
  },
  {
    slug: "codex",
    imgBefore: "challange_codex_before.png",
    imgAfter: "challange_codex_after.png",
  },
  {
    slug: "dewaweb",
    imgBefore: "challange_dewaweb_before.png",
    imgAfter: "challange_dewaweb_after.png",
  },
  {
    slug: "moving-walls",
    imgBefore: "challange_movwalls_before.png",
    imgAfter: "challange_movwalls_after.png",
  },
  {
    slug: "dycodex",
    imgBefore: "challange_dycodex_before.png",
    imgAfter: "challange_dycodex_after.png",
  },
  {
    slug: "mitratel",
    imgBefore: "challange_mitratel_before.png",
    imgAfter: "challange_mitratel_after.png",
  },
  {
    slug: "binar-academy",
    imgBefore: "challange_binar_before.png",
    imgAfter: "challange_binar_after.png",
  },
  {
    slug: "doku",
    imgBefore: "challange_doku_before.png",
    imgAfter: "challange_doku_after.png",
  },
  {
    slug: "qoala",
    imgBefore: "challange_qoala_before.png",
    imgAfter: "challange_qoala_after.png",
  },
  {
    slug: "anantarupastudios",
    imgBefore: "challange_anantarupa_before.png",
    imgAfter: "challange_anantarupa_after.png",
  },
  {
    slug: "mamikos",
    imgBefore: "challange_mamikos_before.png",
    imgAfter: "challange_mamikos_after.png",
  },
  {
    slug: "rekeningku",
    imgBefore: "challange_rekeningku_before.png",
    imgAfter: "challange_rekeningku_after.png",
  },
  {
    slug: "hangry",
    imgBefore: "challange_hangry_before.png",
    imgAfter: "challange_hangry_after.png",
  },
  {
    slug: "idsteknologiindonesia",
    imgBefore: "challange_ids_before.png",
    imgAfter: "challange_ids_after.png",
  },
  {
    slug: "nawadatasolutions",
    imgBefore: "challange_nawadata_before.png",
    imgAfter: "challange_nawadata_after.png",
  },
  {
    slug: "xendit",
    imgBefore: "challange_xendit_before.png",
    imgAfter: "challange_xendit_after.png",
  },
  {
    slug: "jti",
    imgBefore: "challange_ibm_before.png",
    imgAfter: "challange_ibm_after.png",
  },
  {
    slug: "inditechnology",
    imgBefore: "challange_indi_before.png",
    imgAfter: "challange_indi_after.png",
  },
]

const getHours = (hours) => {
  return Date.parse("01 Jan 1970 01:00:00 GMT") * hours
}

const LandingPlayground = ({ dataPerusahaan }) => {
  const [showWof, setShowWof] = useState(false)
  const filteredPerusahaanList = perusahaanList.filter(({ slug }) => {
    const bukaHariApa = dataPerusahaan.find((elem) => elem.slug === slug)
    if (!bukaHariApa) return true
    const startTime =
      Date.parse(new Date()) -
      Date.parse(new Date(bukaHariApa.availableFrom)) +
      getHours(7)
    const endTime =
      Date.parse(new Date(bukaHariApa.availableTo)) -
      Date.parse(new Date()) +
      getHours(17)
    return startTime >= 0 && endTime >= 0
  })

  return (
    <Styles>
      <div className="tenants">
        {filteredPerusahaanList.map(({ slug, imgBefore, imgAfter }, index) => {
          const TheLink = ({ children }) =>
            slug !== "wof" ? (
              <Link to={`/playground/${slug}`}>{children}</Link>
            ) : (
              <a className="wof" onClick={() => setShowWof(true)}>
                {children}
              </a>
            )
          return (
            <div className={`company-${index + 1} ${slug}`} key={index}>
              <TheLink>
                <div className="not-hover">
                  <Image imgName={imgBefore} />
                </div>
                <div className="on-hover">
                  <Image imgName={imgAfter} />
                </div>
              </TheLink>
            </div>
          )
        })}
      </div>
      {showWof && <WheelOfFortune onClose={() => setShowWof(false)} />}
    </Styles>
  )
}

LandingPlayground.propTypes = {
  dataPerusahaan: PropTypes.object,
}

export default LandingPlayground
