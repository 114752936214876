import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Button from "../../Button"
import Fullpage from "../../fullpage"
import wheelSvg from "./wheel.svg"
import pointerSvg from "./pointer.svg"
import playground from "../../../api/zeus/playground"

import { toastActions } from "../../../redux/toast"

const WheelSpin = styled.img`
  transition: none;
  transform: rotateZ(${(props) => props.deg}deg);

  &.spin {
    transition: all 5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
`

const WheelOfFortuneStyle = styled.div`
  max-width: 540px;
  text-align: center;

  h1 {
    background: -webkit-linear-gradient(135deg, #ffcb15 0%, #ec006a 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin: 96px 0 16px;
    font-size: 48px;
    display: inline-block;
    text-align: center;
  }

  .wheeldiv {
    position: relative;
    margin: 66px 0 36px;

    img[alt="wheel"] {
      width: 100%;
      max-width: 410px;
    }

    img[alt="pointer"] {
      width: 42px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -26px);
    }
  }

  .loader {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  div:last-child {
    width: 100%;
    max-width: 452px;

    button {
      font-weight: bold;
      width: 100%;
    }
  }
`

const randomPosition = (point) => {
  const points = [400, 50, 250, 50, 100, 150]
  const offset = Math.random() * 60
  if (point === 50) {
    if (Math.random() < 0.5) {
      return offset + 30
    } else {
      return offset + 150
    }
  } else {
    return offset + points.indexOf(point) * 60 - 30
  }
}

const WheelOfFortune = ({ onClose }) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("show-modal")
    return () => {
      document.getElementsByTagName("body")[0].classList.remove("show-modal")
    }
  })

  const [deg, setDeg] = useState(0)
  const [spin, setSpin] = useState(true)
  const [loading, setLoading] = useState(false)

  const spinTheWheel = async () => {
    setLoading(true)
    try {
      const data = await playground.playWoF()
      setLoading(false)
      const position = randomPosition(data.points)
      setDeg(3600 + position)
      setTimeout(() => {
        toastActions.showToast(`Congratulations! You get ${data.points} Points`)
        // reset position
        setSpin(false)
        setTimeout(() => {
          setDeg(position)
          setTimeout(() => {
            setSpin(true)
          }, 10)
        }, 10)
      }, 5000)
    } catch (error) {
      setLoading(false)
      if (error.response.status === 403) {
        toastActions.showToast("You need to play more challenges.")
      }
    }
  }

  return (
    <Fullpage onClose={onClose}>
      <WheelOfFortuneStyle className="layout">
        <h1>WHEEL OF FORTUNE</h1>
        <p>
          Congratulations! You have won a chance to spin the wheel of fortune
          and earn extra points! Don’t miss your chance now!
        </p>
        <div className="wheeldiv">
          <WheelSpin
            src={wheelSvg}
            deg={deg}
            alt="wheel"
            className={spin && "spin"}
          />
          <img src={pointerSvg} alt="pointer" />
        </div>
        <Button
          onClick={spinTheWheel}
          disabled={loading}
          style={{ marginBottom: "16px" }}
        >
          Spin The Wheel! {loading && <span className="loader" />}
        </Button>
      </WheelOfFortuneStyle>
    </Fullpage>
  )
}

export default WheelOfFortune
