import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import PlaygroundPage from "../../components/PlaygroundPage"
import StickyPointsBar from "../../components/PlaygroundPage/stickyPointsBar"
import LandingPlayground from "../../components/PlaygroundPage/LandingPlayground"
// import Tenant from "../../components/PlaygroundPage/About/Tenant"
import SponsorMedpar from "../../components/SponsorMedpar"

import challangeEmail from "../../../assets/images/playground-assets/challange_email.svg"
import challangeLine from "../../../assets/images/playground-assets/challange_line.svg"
import challangeLinkedin from "../../../assets/images/playground-assets/challange_linkedin.svg"
import challangeInstagram from "../../../assets/images/playground-assets/challange_instagram.svg"
import challangeFacebook from "../../../assets/images/playground-assets/challange_facebook.svg"
import challangeTwitter from "../../../assets/images/playground-assets/challange_twitter.svg"

const PlaygroundChallangeStyle = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;

  > * {
      margin: 0 0.75rem;
      @media (max-width: 576px) {
        margin: 0 0.5rem;
      }

      @media (max-width: 350px) {
        margin: 0 0.1rem;
      }
    }
  }
`

const Playground = ({ data }) => {
  return (
    <Layout>
      <SEO title="Playground" />
      <div className="layout">
        <PlaygroundPage />
      </div>
      <StickyPointsBar landing />
      <LandingPlayground dataPerusahaan={data.allContentfulPerusahaan.nodes} />
      <div
        className="layout"
        style={{ paddingTop: "96px", paddingBottom: "24px" }}
      >
        <h1 style={{ textAlign: "center", fontSize: "24px" }}>
          <FormattedMessage id="playground.shareMessage" />
        </h1>
        <PlaygroundChallangeStyle>
          <a href="https://www.facebook.com/COMPFEST/">
            <img src={challangeFacebook} alt="Facebook" />
          </a>
          <a href="https://twitter.com/compfest">
            <img src={challangeTwitter} alt="Twitter" />
          </a>
          <a href="https://line.me/R/ti/p/%40compfest">
            <img src={challangeLine} alt="Line" />
          </a>
          <a href="https://www.instagram.com/compfest/?hl=id">
            <img src={challangeInstagram} alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/company/compfest">
            <img src={challangeLinkedin} alt="Linkedin" />
          </a>
          <a href="mailto:contact@compfest.id">
            <img src={challangeEmail} alt="Email" />
          </a>
        </PlaygroundChallangeStyle>
        <SponsorMedpar />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulPerusahaan {
      nodes {
        availableFrom
        availableTo
        slug
      }
    }
  }
`

export default Playground
