import styled from "styled-components"
import challengeBgDesktop from "../../../../assets/images/playground-assets/challenge_background_desktop.svg"
import challengeBgMobile from "../../../../assets/images/playground-assets/challenge_background_mobile.svg"

function buatPolaCSS() {
  let polaCss = ""
  let marginTop = -29
  for (let i = 1; i <= 20; i += 1) {
    if (i % 2) {
      marginTop += 34
    } else {
      marginTop += 27
    }
    polaCss += `
      .company-${i} {
        margin-left: ${i % 2 ? `8%` : `50%`};
        margin-top: ${marginTop}%;
      }
     `
  }

  return polaCss
}

export const Styles = styled.div`
  width: 80%;
  margin: auto;

  a.wof:hover {
    cursor: pointer;
  }

  .tenants {
    padding-bottom: 115%; /* to maintain aspect ratio */
    background: url(${challengeBgDesktop}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    & > * {
      width: 14.3%;
      position: absolute;

      .on-hover {
        display: none;
      }
    }

    & > *:hover {
      /* TODO: Make some cool transition */
      .not-hover {
        display: none;
      }

      .on-hover {
        display: block;
      }
    }

    .company-1 {
      margin-left: 42%;
      margin-top: 16.5%;
    }

    .company-2 {
      margin-left: 56%;
      margin-top: 25.5%;
    }

    .company-3 {
      margin-left: 55%;
      margin-top: 5%;
    }

    .company-4 {
      margin-left: 4%;
      margin-top: 15.5%;
    }

    .company-5 {
      margin-left: 24%;
      margin-top: 3%;
    }

    .company-6 {
      margin-left: 23.8%;
      margin-top: 26.5%;
    }

    .company-7 {
      margin-left: 44%;
      margin-top: 38.5%;
    }

    .company-8 {
      margin-left: 28%;
      margin-top: 48.7%;
    }

    .company-9 {
      margin-left: 2%;
      margin-top: 39%;
    }

    .company-10 {
      margin-left: 6%;
      margin-top: 55%;
    }

    .company-11 {
      margin-left: 13.5%;
      margin-top: 72.5%;
    }

    .company-12 {
      margin-left: 38%;
      margin-top: 68%;
    }

    .company-13 {
      margin-left: 59%;
      margin-top: 50%;
    }

    .company-14 {
      margin-left: 61%;
      margin-top: 74%;
    }

    .company-15 {
      margin-left: 0%;
      margin-top: 0%;
    }

    .company-16 {
      margin-left: 0%;
      margin-top: 0%;
    }

    .compfest {
      width: 20%;

      .on-hover {
        margin-top: -15%;
      }
    }

    .icehouse {
      width: 14%;

      .on-hover {
        margin-top: -13%;
      }
    }

    .pinhome {
      width: 15%;

      .on-hover {
        margin-top: -10%;
      }
    }

    .binar-academy {
      width: 16%;
      .on-hover {
        margin-top: -6%;
      }
    }

    .crewdible {
      width: 18%;
    }

    .mitratel {
      width: 15%;
    }

    .althaconsulting {
      width: 19%;
    }

    .mamikos {
      width: 13.5%;
    }

    .anantarupastudios {
      width: 15.5%;
    }

    .inditechnology {
      width: 18%;
    }

    .megaxus .on-hover {
      margin-top: -15%;
    }

    .antigrvty .on-hover {
      margin-top: -13%;
    }

    .shopee .on-hover {
      margin-top: -8%;
    }

    .wof .on-hover {
      margin-top: -17%;
    }

    .higo .on-hover {
      margin-top: -10%;
    }

    .stockbit .on-hover {
      margin-top: -10%;
    }

    .codex .on-hover {
      margin-top: -7%;
    }

    .moving-walls .on-hover {
      margin-top: -15%;
    }

    .skilvul .on-hover {
      margin-top: -7%;
    }

    .doku .on-hover {
      margin-top: -15%;
    }

    .anantarupastudios .on-hover {
      margin-top: -10%;
    }

    .mamikos .on-hover {
      margin-top: -8%;
    }

    .rekeningku .on-hover {
      margin-top: -10%;
    }

    .hangry .on-hover {
      margin-top: -15%;
    }

    .jti .on-hover {
      margin-top: -15%;
    }

    .idsteknologiindonesia .on-hover {
      margin-top: -15%;
    }
  }

  @media screen and (max-width: 575px) {
    width: 90%;

    .tenants {
      padding-bottom: 517%; /* to maintain aspect ratio */
      background: url(${challengeBgMobile}) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      & > * {
        width: 35%;

        .on-hover {
          margin-top: -5%;
        }
      }

      ${buatPolaCSS()}

      .company-1 {
        width: 38%;
        margin-top: 12%;
        margin-left: 6%;
      }

      .compfest {
        margin-top: 39%;
        margin-left: 44%;
        width: 45%;
      }

      .crewdible {
        width: 45%;
      }

      .althaconsulting {
        transform: translateY(-13%);
        width: 45%;
      }

      .icehouse {
        transform: translateY(-13%);
        width: 35%;
      }

      .ecomindo {
        transform: translateY(-40%);
      }

      .sirclo {
        transform: translateY(-20%);
      }

      .hangry {
        transform: translateY(20%);
      }

      .higo {
        transform: translateY(14%);
      }

      .shopee {
        transform: translateY(6%);
      }

      .moving-walls {
        transform: translateY(-10%);
      }

      .dycodex {
        transform: translateY(-35%);
      }

      .xendit {
        transform: translateY(-35%);
      }

      .idsteknologiindonesia {
        transform: translateY(-10%);
      }

      .jti {
        transform: translateY(-20%);
      }

      .stockbit {
        width: 34%;
      }

      .anantarupastudios {
        width: 39%;
      }

      .mamikos {
        width: 34%;
      }

      .pinhome {
        width: 38%;
      }

      .inditechnology {
        transform: translateY(-30%);
        width: 45%;
      }

      .dewaweb {
        width: 38%;
        transform: translateY(-14%);
      }

      .mitratel {
        width: 36%;
        transform: translate(-10%, -15%);
      }

      .binar-academy {
        width: 36%;
        transform: translate(15%, -30%);
      }
    }
  }
`
